/* eslint-disable prettier/prettier */
import { createFilterOptions } from "@material-ui/lab/useAutocomplete";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { AutocompleteElement, Chevron, Container, Dropdown, Input, Label } from "./styles";
import type { SelectSearchProps } from "./types";

export default function SelectSearch({ className, label, id, placeholder, options, disabled, value, handleValueChange, elementRef }: SelectSearchProps): JSX.Element {
	const filterOptions = createFilterOptions({
		matchFrom: "start"
	});
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label ? (
					<Label $bold as="label" htmlFor={id} $isDisabled={disabled}>
						{label}
					</Label>
				) : (
					""
				)}

				<AutocompleteElement id={id} popupIcon={<Chevron />} disabled={disabled} $isDisabled={disabled} openOnFocus value={value ? value : null} onChange={handleValueChange} options={options} getOptionLabel={(option: any) => (option?.label ? option?.label : "")} filterOptions={filterOptions} renderInput={(params) => <Input placeholder={placeholder} {...params} inputRef={elementRef} />} PaperComponent={({ children }) => <Dropdown elevation={0}>{children}</Dropdown>} />
			</Container>
		</ThemeProvider>
	);
}
