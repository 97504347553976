import Button from "../Button";
import IconButton from "../IconButton";
import Tag from "../Tag";
import { Caption, Overline, Small } from "../Typography";
import styled, { css } from "styled-components";

import { $IconButtonProps, $StyledContainerProps } from "./types";

export const Container = styled.div`
	width: 100%;
	min-width: 272px;
	background-color: ${({ theme }) => theme.colors.neutral100};
	border-radius: 0.75rem;
	inset-block-start: 0.625rem;
	padding-block: 1rem;
	padding-inline: 1rem;
	max-height: 100%;
	overflow-y: scroll;
`;

export const Circle = styled(Overline).attrs({
	as: "div"
})<$StyledContainerProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 1.25rem;
	height: 1.25rem;
	color: transparent;
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 1.5625rem;
	user-select: none;

	${({ $checked }) =>
		$checked &&
		css`
			color: ${({ theme }) => theme.colors.yellowBee800};
			background-color: ${({ theme }) => theme.colors.yellowBee200};
			border: 2px solid ${({ theme }) => theme.colors.yellowBee200};

			// :before {
			// 	position: absolute;
			// 	inset-block-start: -0.375rem;
			// 	width: 0.125rem;
			// 	border-top: 0.25rem solid ${({ theme }) => theme.colors.white};
			// 	border-bottom: 0;
			// 	content: "";
			// }
		`}

	${({ $missed }) =>
		$missed &&
		css`
			border: 2px solid ${({ theme }) => theme.colors.yellowBee200};
		`}
`;

export const Description = styled(Caption)<$StyledContainerProps>`
	margin-block-start: 0.125rem;
	color: ${({ theme }) => theme.colors.neutral500};
`;

export const Step = styled.div<$StyledContainerProps>`
	margin-inline-start: 1.25rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-block: 0.5rem;

	${({ $active, theme }) => {
		if ($active) {
			return css`
				background-color: ${theme.colors.white};
				border-radius: 0.375rem;
			`;
		}
	}}
`;

export const TagWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-height: 1.5rem;
	padding-inline: 0.5rem;
`;

export const IconWrapper = styled.div<$IconButtonProps>`
	display: ${({ $hiddenAccordion }) => $hiddenAccordion && "none"};
`;

export const ArrowIconButton = styled(IconButton)``;

export const InfoTag = styled(Tag)`
	> p {
		font-size: 0.75rem;
	}
`;

export const Title = styled(Small)<$StyledContainerProps>`
	flex: 1;
	width: 100%;
	min-width: 10.125rem;
`;

export const StepContent = styled.div<$StyledContainerProps>`
	display: flex;
	padding-block-end: 0.75rem;
	align-items: baseline;
	:before {
		position: absolute;
		z-index: -1;
		height: ${({ $checked, $missed }) => ($checked || $missed ? "calc(100% - 0.65rem)" : "100%")};
		border-inline-start: 2px solid ${({ $checked, $missed, theme }) => ($checked || $missed ? theme.colors.yellowBee200 : theme.colors.neutral200)};
		content: "";
		inset-inline-start: 0.5625rem;
		inset-block-start: 0.75rem;
	}
`;

export const StepWrapper = styled.li<$StyledContainerProps>`
	position: relative;
	z-index: 0;
	:last-of-type {
		${StepContent} {
			:before {
				border: transparent;
			}
		}
	}
`;

export const InfoHidden = styled(Small).attrs({ as: "div" })`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	max-width: 13.25rem;
	padding-inline: 0.5rem;
	border-radius: 0.375rem;
`;

export const InfoDetailWrapper = styled.div``;

export const Date = styled(Small)`
	font-size: 1rem;
`;

export const TitleWrapper = styled.div<$StyledContainerProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 0.5rem;
	margin-block-start: -1rem;
	${({ $active, theme }) => {
		if ($active) {
			return css`
				border-bottom: 1px solid ${theme.colors.neutral200};
				padding-block-end: 0.5625rem;
			`;
		}
	}}
`;

export const AccordionIconButton = styled(IconButton)<$IconButtonProps>`
	transform: ${({ $hiddenAccordion }) => ($hiddenAccordion ? "rotate(180deg)" : "rotate(0deg)")};
	transition: transform 0.2s ease-in-out;
	padding-inline: 0;
	margin-inline-end: 0.5rem;
	&:hover {
		background-color: transparent;
	}
`;

export const LinkButton = styled(Button)`
	padding-block: 1.5rem 0.5rem;
	padding-inline: 0;
	color: ${({ theme }) => theme.colors.supportError400};
`;

export const List = styled.ol``;
