import StyledDropdownButton from "../DropdownButton";
import { Button, DropdownWrapper } from "../DropdownButton/styles";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	color: ${({ theme }) => theme.colors.neutral900};
	border-bottom: solid 1px ${({ theme }) => theme.colors.neutral300};
`;

export const TabsUl = styled.ul`
	display: flex;
	align-items: stretch;
	min-height: 2rem;
	list-style: none;
`;

export const TabsLi = styled.li`
	width: auto;

	& + & {
		margin-inline-start: 1.125rem;
	}
`;

export const IconContainer = styled.div`
	display: flex;
	width: 1rem;
	height: 1rem;
	color: inherit;
`;

export const DropdownButton = styled(StyledDropdownButton)`
	width: fit-content;
	height: fit-content;

	${Button} {
		padding-inline: 0.5rem;
		padding-block: 0.5rem;
	}

	${DropdownWrapper} {
		min-width: 9.1875rem;
	}
`;
