import { Button } from "../../components/Typography";
import styled, { css } from "styled-components";

import { $ContainerProps } from "./types";

export const IconContainer = styled.span`
	display: flex;
	align-items: center;
`;

export const ButtonLabel = styled(Button)``;

export const Container = styled.span<$ContainerProps>`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	border-radius: 0.375rem;

	${IconContainer} {
		> svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	${({ $size }) => {
		switch ($size) {
			case "small":
				return css`
					gap: "0.375rem";
					height: 2.25rem;
					padding-inline: 0.75rem;
					padding-block: 0.4375rem;
					line-height: 1.375rem;
					${ButtonLabel} {
						width: auto;
						font-size: 0.875rem;
						line-height: 1.375rem;
					}
				`;
			case "medium":
				return css`
					gap: 0.5rem;
					height: 2.5rem;
					padding-inline: 0.75rem;
					padding-block: 0.5rem;
					line-height: 1.5rem;
				`;
			case "big":
			default:
				return css`
					gap: 0.75rem;
					height: 3rem;
					padding-inline: 1rem;
					padding-block: 0.75rem;
					line-height: 1.5rem;
				`;
		}
	}}

	${({ theme, $variant, $disabled }) => {
		switch ($variant) {
			case "outlined":
				return css`
					color: ${theme.colors.neutral900};
					background-color: transparent;
					opacity: ${$disabled ? "0.36" : "1"};
					transition: background-color 0.2s ease-in-out;
					border: 1px solid ${theme.colors.neutral300};
					border-radius: 0.5rem;
					&:hover {
						${!$disabled && `border: 1px solid ${theme.colors.neutral900};`}
					}
					&:focus-visible {
						${!$disabled && `border: 1px solid ${theme.colors.neutral900};`}
					}
				`;
			case "no-bg":
				return css`
					color: ${$disabled ? theme.colors.neutral600 : theme.colors.purpleSunset400};
					background-color: transparent;
					opacity: ${$disabled ? "0.36" : "1"};
					transition: background-color 0.2s ease-in-out;
					&:hover {
						${!$disabled && `color: ${theme.colors.purpleSunset600}`}
					}
					&:focus-visible {
						${!$disabled && `color: ${theme.colors.purpleSunset600}`}
					}
				`;
			case "secondary":
				return css`
					background-color: ${theme.colors.neutral200};
					opacity: ${$disabled ? "0.36" : "1"};
					transition: background-color 0.2s ease-in-out;
					&:hover {
						${!$disabled && `background-color: ${theme.colors.neutral300}`}
					}
					&:focus-visible {
						background-color: ${theme.colors.neutral200};
						outline: ${$disabled ? "transparent" : theme.colors.neutral100} solid 0.25rem;
					}
				`;
			case "primary":
			default:
				return css`
					background-color: ${theme.colors.yellowBee200};
					opacity: ${$disabled ? "0.36" : "1"};
					transition: background-color 0.2s ease-in-out;
					&:hover {
						${!$disabled && `background-color: ${theme.colors.yellowBee100}`}
					}
					&:focus-visible {
						background-color: ${theme.colors.yellowBee200};
						outline: ${$disabled ? "transparent" : theme.colors.yellowBee100} solid 0.25rem;
					}
				`;
		}
	}}
`;
