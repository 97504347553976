import styled from "styled-components";
import Button from "../Button";
import Tabs from "../Tabs";
import { TabLabel } from "../Tabs/Tab/styles";

export const Container = styled.div`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	border-radius: 0.375rem;
	transition: background-color ease-in-out 0.2s;
	width: 100%;
`;
export const MainActions = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	margin: 0rem;
	border-bottom: solid 1px ${({ theme }) => theme.colors.neutral300};
`;
export const Toolbar = styled.div`
	margin-left: auto; // Pushes the Toolbar to the right
	display: flex;
	// gap: 0.5rem; // Add some gap between toolbar buttons
	& MuiIconButton-root {
		&:last-child {
			padding-right: 0;
		}
	}
`;
export const ActionButton = styled(Button)`
	flex-shrink: 0;
	margin-inline-start: 0.25rem;
	margin-inline-end: 0.25rem;
	&:first-child {
		margin-inline-start: 0;
	}
	&:last-child {
		margin-inline-end: 0;
	}
`;
export const BoxTabs = styled(Tabs)`
	border: none;
	${TabLabel} {
		margin-inline: 0;
	}
`;
