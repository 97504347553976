import { Small } from "../Typography";
import styled, { css } from "styled-components";

import { StylesProps } from "./types";

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
	// min-height: 6.5rem;
`;

export const Label = styled(Small)``;

export const StyledInput = styled.input<StylesProps>`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral300};
	border-radius: 0.5rem;

	::placeholder {
		color: ${({ theme }) => theme.colors.neutral600};
	}

	:focus {
		border-color: ${({ theme }) => theme.colors.neutral900};
		outline: none;
	}

	:disabled {
		background-color: ${({ theme }) => theme.colors.neutral100};
		border-color: ${({ theme }) => theme.colors.neutral300};
		cursor: not-allowed;
		::placeholder {
			color: ${({ theme }) => theme.colors.neutral400};
		}
	}
	${({ $size }) => {
		switch ($size) {
			case "small":
				return css`
					padding-inline: 0.75rem;
					padding-block: 0.438rem;
					font-size: 0.875rem;
				`;
			default:
				return css`
					padding-block: 0.5rem;
					padding-inline: 0.75rem;
				`;
		}
	}}

	${({ theme, $hasError }) =>
		$hasError &&
		css`
			border-color: ${theme.colors.supportError100} !important;
			// padding-inline-end: 2.5rem;
		`}
	${({ $hasIcon }) =>
		$hasIcon &&
		css`
			padding-inline-end: 2rem;
		`}
`;

export const IconWrapper = styled.div<StylesProps>`
	position: absolute;
	width: 1rem;
	height: 1rem;
	inset-inline-end: 0.75rem;
	inset-block-start: 1.15em;
	color: ${({ theme }) => theme.colors.supportError200};
	background-color: ${({ theme }) => theme.colors.white};
`;

export const DefaultIconWrapper = styled.div`
	position: absolute;
	width: 1rem;
	height: 1rem;
	inset-inline-end: 0.75rem;
	inset-block-start: 0.75em;
	color: ${({ theme }) => theme.colors.neutral600};
	background-color: ${({ theme }) => theme.colors.white};
`;

export const Error = styled(Small)`
	color: ${({ theme }) => theme.colors.supportError200};
`;

export const Helper = styled(Small)`
	color: ${({ theme }) => theme.colors.neutral600};
`;
