/* eslint-disable prettier/prettier */
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { ActionButton, BoxTabs, Container, MainActions, Toolbar } from "./styles";
// Removed the import for styles as it's not a module

export default function SubHeader({ tabConfigs = [], buttonConfigs = [], currentTab, setCurrentTab }): JSX.Element {
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<MainActions>
					<BoxTabs tabs={tabConfigs} hideAddTab={true} currentTab={currentTab} onTab={setCurrentTab} />
					<Toolbar>{buttonConfigs.length !== 0 && buttonConfigs.map((buttonConfig, index) => <ActionButton key={index} disabled={buttonConfig.disabled} size={buttonConfig.size} variant={buttonConfig.variant} onClick={buttonConfig.onClick} label={buttonConfig.label} />)}</Toolbar>
				</MainActions>
			</Container>
		</ThemeProvider>
	);
}
