const light = {
	colors: {
		yellowBee800: "#664400",
		yellowBee600: "#996600",
		yellowBee400: "#CC8800",
		yellowBee200: "#FDB933",
		yellowBee100: "#FFE4AD",
		yellowBee50: "#FFF5E0",

		purpleSunset800: "#29204B",
		purpleSunset600: "#422E94",
		purpleSunset400: "#6245D9",
		purpleSunset200: "#8B74E7",
		purpleSunset100: "#CEC2FF",
		purpleSunset50: "#EBE5FF",

		oceanicBlue800: "#094652",
		oceanicBlue600: "#0C5B6E",
		oceanicBlue400: "#137790",
		oceanicBlue200: "#45ABC4",
		oceanicBlue100: "#B4E5EE",
		oceanicBlue50: "#DEF3F8",

		neutral100: "#F6F6F7",
		neutral200: "#EDEEEE",
		neutral300: "#C5C9CA",
		neutral400: "#ACB1B3",
		neutral500: "#828A8D",
		neutral600: "#677073",
		neutral700: "#4D585C",
		neutral800: "#313E42",
		neutral900: "#18262B",

		supportError800: "#7A1000",
		supportError400: "#EB1F00",
		supportError200: "#FF6952",
		supportError100: "#FFB8AD",
		supportError50: "#FFE9E6",

		supportWarning800: "#612D00",
		supportWarning400: "#B75500",
		supportWarning200: "#FF9A42",
		supportWarning100: "#FFCA9B",
		supportWarning50: "#FFF0E3",

		supportSuccess800: "#19311E",
		supportSuccess400: "#00A91C",
		supportSuccess200: "#7FDD89",
		supportSuccess100: "#B7F5BD",
		supportSuccess50: "#E3F5E1",

		white: "#FFFFFF"
	},
	fonts: {
		main: "HK Grotesk"
	},
	weights: {
		regular400: 400,
		medium500: 500,
		bold700: 700
	},
	shadows: {
		shadow8: "0px 4px 8px #100F1629",
		shadow12: "0px 6px 12px rgba(16, 15, 22, 0.06)",
		shadow24: "0px 12px 24px rgba(16, 15, 22, 0.08)",
		shadow48: "0px 24px 48px rgba(16, 15, 22, 0.10)",
		shadow60: "4px 0px 12px -7px rgba(23, 23, 23, 0.15)",
		shadow72: "3px 0px 5px rgba(23,23,23,0.07)",
		shadow84: "-3px 0px 5px rgba(23,23,23,0.07)"
	},
	transparent: {
		transparent1200: "rgba(255, 255, 255, 0.12)",
		transparent400: "rgba(255, 255, 255, 0.4)",
		yellow: "rgba(254, 192, 72, 0.12)"
	}
};

export default light;
