import { AutocompleteElement, Chevron, Container, Dropdown, Input, Label, StyledCheckbox } from "./styles";
import type { SelectCheckboxesSearchProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Checkbox, CheckboxCheckedFilled } from "@carbon/icons-react";

const icon = <Checkbox size={24} />;
const checkedIcon = <CheckboxCheckedFilled size={24} />;

export default function SelectCheckboxesSearch({ id, className, disabled, name, options, placeholder, label, value, elementRef, onChange }: SelectCheckboxesSearchProps): JSX.Element {
	// const { arrayValue, setArrayValue, filteredOptions, setFilteredOptions, shownTags, tagsListRef, clearAll } = useLogic(options, value);
	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label ? (
					<Label $bold as="label" htmlFor={id} $isDisabled={disabled}>
						{label}
					</Label>
				) : (
					""
				)}
				<AutocompleteElement
					multiple
					openOnFocus
					limitTags={2}
					id={id}
					options={options}
					value={value}
					disableCloseOnSelect
					onChange={onChange}
					getOptionLabel={(option: any) => option.label}
					isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
					renderOption={(props, option: any, { selected }) => {
						const { children, ...optionProps } = props;
						return (
							<li key={option.value} {...optionProps}>
								<StyledCheckbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
								{option.label}
							</li>
						);
					}}
					renderInput={(params) => <Input {...params} placeholder={placeholder} inputRef={elementRef} />}
					PaperComponent={({ children }) => <Dropdown elevation={0}>{children}</Dropdown>}
					popupIcon={<Chevron />}
				/>
			</Container>
		</ThemeProvider>
	);
}
