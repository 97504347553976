/* eslint-disable prettier/prettier */
import React, { useRef, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";

// Reusable cell renderer with tooltip for overflow
export const withTooltip =
	(CellComponent) =>
	({ cell }) => {
		const cellRef = useRef<HTMLDivElement>(null);
		const [showTooltip, setShowTooltip] = useState(false);

		const cellValue = cell.getValue();

		useEffect(() => {
			if (cellRef.current) {
				const { scrollWidth, clientWidth } = cellRef.current;
				setShowTooltip(scrollWidth > clientWidth);
			}
		}, [cellRef, cellValue]);

		return (
			<Tooltip title={showTooltip ? String(cellValue) : ""}>
				<div
					ref={cellRef}
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						width: "100%" // Ensure it takes the full width of the cell
					}}
				>
					<CellComponent cell={cell} />
				</div>
			</Tooltip>
		);
	};

// Default cell renderer
export const DefaultCell = ({ cell }) => {
	return <span>{cell.getValue() ? cell.getValue() : "-"}</span>;
};
