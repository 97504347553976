import { Warning } from "@carbon/icons-react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";

import { Container, DefaultIconWrapper, Error, Helper, IconWrapper, Label, StyledInput } from "./styles";
import type { InputTextProps } from "./types";

import { useRef, useCallback } from "react";

function useDebounce(callback: (...args: any[]) => void, delay: number) {
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const debouncedFunction = useCallback(
		(...args: any[]) => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
			timerRef.current = setTimeout(() => {
				callback(...args);
			}, delay);
		},
		[callback, delay]
	);

	return debouncedFunction;
}

export default function InputText({ autoComplete, className, disabled, error, help, helpMessage, id, label, name, defaultValue, onBlur, onChange, onFocus, onKeyDown, onMouseLeave, pattern, placeholder, readOnly, required, errorMessage, elementRef, debounceTime, icon, size }: InputTextProps): JSX.Element {
	// Create a debounced version of the onChange function
	const debouncedOnChange = useDebounce(onChange, debounceTime);

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container className={className}>
				{label ? (
					<Label $bold as="label" htmlFor={name}>
						{label}
					</Label>
				) : (
					""
				)}
				<StyledInput $hasError={!!error} $hasIcon={!!icon} $size={size} id={id} name={name} defaultValue={defaultValue} onBlur={onBlur} onChange={debouncedOnChange} onKeyDown={onKeyDown} onFocus={onFocus} pattern={pattern} placeholder={placeholder} required={required} type="text" autoComplete={autoComplete} readOnly={readOnly} disabled={disabled} ref={elementRef} onMouseLeave={onMouseLeave} />
				{error ? (
					<IconWrapper $hasError={!!error}>
						<Warning />
					</IconWrapper>
				) : null}
				{icon ? <DefaultIconWrapper>{icon}</DefaultIconWrapper> : null}
				{error ? <Error role="alert">{errorMessage}</Error> : null}
				{help && !error ? <Helper>{helpMessage}</Helper> : null}
			</Container>
		</ThemeProvider>
	);
}
