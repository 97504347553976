import styled, { createGlobalStyle } from "styled-components";
import Button from "../Button";
import { Title } from "../Typography";
import { prettyScrollbar } from "../../styles/mixins";
import { List, Popover, Checkbox } from "@mui/material";
import InputSearch from "../InputSearch";

interface TableContainerProps {
	leftPinnedcolumn?: number; // Adjust the type as needed
	rightPinnedcolumn?: number;
}

export const Container = styled.div`
	width: 100%;
	min-width: 50.25rem;
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	box-shadow: ${({ theme }) => theme.shadows.shadow12};
	border-radius: 0.5rem;
	margin-top: 1.5rem;
`;
export const Content = styled.div`
	${prettyScrollbar({ vertical: false })}
	overflow-x: auto;
`;
export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	// margin-block-end: 1rem;
	padding-inline: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	gap: 0.75rem;
`;
export const MainTitle = styled(Title)`
	font: normal normal 600 16px/24px HK Grotesk;
	letter-spacing: 0px;
	color: #100f16;
`;
export const MainActions = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding-inline-start: 0.85rem;
`;

export const FilterActions = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

export const MoreDropdown = styled(Popover)``;

export const PopoverContent = styled.div`
	padding: 0.75rem;
`;
export const StyledList = styled(List)`
	max-height: 200px;
	overflow-y: auto;
	& .MuiListItem-padding {
		padding: 0;
	}
	& .MuiTypography-root {
		font-family: ${({ theme }) => theme.fonts.main};
		color: ${({ theme }) => theme.colors.neutral900};
	}
`;
export const StyledCheckbox = styled(Checkbox)`
	&.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${({ theme }) => theme.colors.neutral900};
	}
	&.MuiCheckbox-box {
		border-color: ${({ theme }) => theme.colors.neutral900};
	}
`;

export const Toolbar = styled.div`
	margin-left: auto; // Pushes the Toolbar to the right
	display: flex;
	// gap: 0.5rem; // Add some gap between toolbar buttons
	& MuiIconButton-root {
		&:last-child {
			padding-right: 0;
		}
	}
`;

export const Divider = styled.div`
	width: 1px;
	// height: 100%;
	background-color: ${({ theme }) => theme.colors.neutral200};
`;
export const FilterActionButton = styled(Button)`
	flex-shrink: 0;
	padding-inline-end: 0;
	padding-inline-start: 0;
`;
export const SearchInputText = styled(InputSearch)`
	min-width: 14.125rem;
`;
export const TableContainer = styled.div<TableContainerProps>`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;

	.MuiTableContainer-root {
		max-height: clamp(40vh, calc(100vh - 345px), 9999px);
	}
	.MuiPaper-rounded {
		background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
		box-shadow: none;
		border-radius: 0.5rem;
	}
	.MuiTableRow-head {
		.Mui-TableHeadCell-Content {
			width: 100%;
		}

		.Mui-TableHeadCell-Content-Labels,
		.Mui-TableHeadCell-Content-Wrapper {
			width: 100%;
		}

		.Mui-TableHeadCell-Content-Wrapper {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block; /* Ensure it respects width */
		}

		.MuiButtonBase-root,
		.Mui-TableHeadCell-Content-Actions,
		.Mui-TableHeadCell-ResizeHandle-Wrapper {
			width: 0%;
			opacity: 0;
		}

		.Mui-TableHeadCell-Content:hover .MuiButtonBase-root,
		.Mui-TableHeadCell-Content:hover .Mui-TableHeadCell-Content-Actions,
		.Mui-TableHeadCell-Content:hover .Mui-TableHeadCell-ResizeHandle-Wrapper {
			width: auto; /* Set to normal width on hover */
			opacity: 1;
		}
		box-shadow: none;
		border-bottom: 1px solid ${({ theme }) => theme.colors.neutral100};
		// border-bottom: 0;
		.MuiTableCell-head {
			border-bottom: 0;
			::before {
				background: ${({ theme }) => theme.colors.white};
			}
			[data-index="${(props) => props.leftPinnedcolumn}"] {
				// Use the prop
				box-shadow: ${({ theme }) => theme.shadows.shadow72};
				::before {
					box-shadow: ${({ theme }) => theme.shadows.shadow72};
					overflow: visible;
				}
			}

			[data-index="${(props) => props.rightPinnedcolumn}"] {
				// Use the prop
				box-shadow: ${({ theme }) => theme.shadows.shadow84};
				::before {
					box-shadow: ${({ theme }) => theme.shadows.shadow84};
				}
			}

			.MuiBox-root {
				.MuiIconButton-root {
					.MuiSvgIcon-root {
						opacity: 0;
						font-size: 18px;
					}
				}
				.MuiBadge-root {
					.MuiTableSortLabel-root {
						.MuiTableSortLabel-icon {
							opacity: 0;
							color: ${({ theme }) => theme.colors.neutral800};
							fill: ${({ theme }) => theme.colors.neutral800};
							font-size: 18px;
						}
					}
				}
			}
			&:hover {
				.MuiBox-root {
					.MuiIconButton-root {
						.MuiSvgIcon-root {
							opacity: 1;
							font-size: 18px;
						}
					}
					.MuiBadge-root {
						.MuiTableSortLabel-root {
							.MuiTableSortLabel-icon {
								opacity: 1;
								color: ${({ theme }) => theme.colors.neutral800};
								fill: ${({ theme }) => theme.colors.neutral800};
								.MuiSvgIcon-root {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
			&:first-child {
				padding-inline-start: 1.5rem;
			}
			&:last-child {
				padding-inline-end: 1.5rem;
			}
			.Mui-TableHeadCell-Content-Wrapper {
				font-family: ${({ theme }) => theme.fonts.main};
				color: ${({ theme }) => theme.colors.neutral900};
			}
			.Mui-TableHeadCell-ResizeHandle-Divider {
				border-width: 1px;
			}
			.MuiInput-underline {
				font-family: ${({ theme }) => theme.fonts.main};
				color: ${({ theme }) => theme.colors.neutral900};
				font: normal normal normal 14px/22px HK Grotesk;
			}
		}
	}
	.MuiTableRow-root {
		&:hover {
			td::after {
				background-color: ${({ theme }) => theme.colors.neutral100} !important;
			}
		}
		[data-pinned="true"] {
			background: ${({ theme }) => theme.colors.white};

			::before {
				background: ${({ theme }) => theme.colors.white};
				overflow: visible;
			}
		}
		[data-index="${(props) => props.leftPinnedcolumn}"] {
			// Use the prop
			box-shadow: ${({ theme }) => theme.shadows.shadow72};
			::before {
				box-shadow: ${({ theme }) => theme.shadows.shadow72};
				overflow: visible;
			}
		}

		[data-index="${(props) => props.rightPinnedcolumn}"] {
			// Use the prop
			box-shadow: ${({ theme }) => theme.shadows.shadow84};
			::before {
				box-shadow: ${({ theme }) => theme.shadows.shadow84};
			}
		}
		.MuiTableCell-body {
			&:first-child {
				padding-inline-start: 1.5rem;
			}
			&:last-child {
				padding-inline-end: 1.5rem;
			}
			font-family: ${({ theme }) => theme.fonts.main};
			color: ${({ theme }) => theme.colors.neutral900};
			border-bottom: 1px solid ${({ theme }) => theme.colors.neutral100};
		}
	}
	.MuiTablePagination-root {
		.MuiInputLabel-root,
		.MuiInputBase-root,
		.MuiPaginationItem-root {
			font-family: ${({ theme }) => theme.fonts.main};
			font: normal normal normal 14px/22px HK Grotesk;
			letter-spacing: 0px;
			color: ${({ theme }) => theme.colors.neutral600};
			.MuiPagination-ul {
				&.Mui-selected,
				&:hover {
					background-color: ${({ theme }) => theme.colors.neutral100};
					border-radius: 6px;
				}
			}
		}
		@media (max-width: 800px) {
			flex-direction: column;
		}
	}
`;

export const PaginationWrapper = styled.div`
	display: flex;
	justify-content: end;
	flex-direction: row;
	padding-inline-start: 1.5rem;
	padding-inline-end: 1.5rem;
	padding: 0.5rem;
	.MuiInputLabel-root,
	.MuiInputBase-root,
	.MuiPaginationItem-root {
		font-family: ${({ theme }) => theme.fonts.main};
		font: normal normal normal 14px/22px HK Grotesk;
		letter-spacing: 0px;
		color: ${({ theme }) => theme.colors.neutral600};
		.MuiPagination-ul {
			&.Mui-selected,
			&:hover {
				background-color: ${({ theme }) => theme.colors.neutral100};
				border-radius: 6px;
			}
		}
	}
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

export const ColumnVisibilityMenuStyle = createGlobalStyle`
  .MuiPopover-root {
	height: 70%;
    .MuiPaper-root {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.neutral200};
      box-shadow: ${({ theme }) => theme.shadows.shadow24};
	    opacity: 1;
      transform: none;
      transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1), transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
      top: 140px;
      left: 992px;
      transform-origin: 238px 111.8px 0px;
      width: 400px;
    }
    .MuiList-root {
		width: calc(100% + 0px);
		display: flex;
		flex-direction: column;	
      .MuiMenuItem-root {
        position: relative;
		.MuiBox-root{
			.MuiBox-root{
				position: absolute;
				right: 0;
				display: flex;
				height: 100%
			}
		}
        .MuiFormControlLabel-root {
          .MuiSwitch-switchBase {
            color: ${({ theme }) => theme.colors.white};
            .MuiSwitch-thumb {
              position: absolute;
              width: 12px;
              height: 12px;
              top: 15px;
              right: -7px;
            }
          }
          .MuiSwitch-root {
            .MuiSwitch-track {
              width: 40px;
              height: 20px;
              border-radius: 12px;
              background-color: ${({ theme }) => theme.colors.neutral500};
            }
            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              background-color: ${({ theme }) => theme.colors.neutral900};
              opacity: 1;
            }
          }
          .MuiTypography-root {
            max-width: 160px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: HK Grotesk;
          }
        }
      }
      .MuiButtonBase-root {
        display: flex;
        justify-content: flex-start !important;
        color: ${({ theme }) => theme.colors.neutral900};
        font-family: HK Grotesk;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
	  .Mui-disabled{
		color: ${({ theme }) => theme.colors.neutral900};
	  }
    }
  }
`;
