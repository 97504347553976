import { Caption } from "../Typography";
import styled from "styled-components";

export const ChartWrapper = styled.div<{ height?: number }>`
	width: 100%;
	overflow-x: auto;
	height: ${(p) => p.height + "px" || "12.8rem"};
`;
export const Legend = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const ValueItem = styled(Caption)`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral600};
	margin-inline-end: 1rem;
`;
export const ColorLabel = styled.span`
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.25rem;
	margin-inline-end: 0.5rem;
`;
// tooltip
export const Card = styled.div`
	min-width: 1rem;
	min-height: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.neutral200};
	border-radius: 0.2rem;
	box-shadow: ${({ theme }) => theme.shadows.shadow8};
	padding-block: 0.2rem;
	padding-inline: 0.2rem;
`;
export const Item = styled(Caption)`
	color: ${({ theme }) => theme.colors.neutral900};
`;
