import { useState } from "react";

type AccordionProps = {
	id: number;
	isHidden: boolean;
};

export const useLogic = (accordionIds: number[], lastCheckedStepId) => {
	const [hiddenAccordion, setAccordionHidden] = useState<AccordionProps[]>(accordionIds.map((value: number) => ({ id: value, isHidden: value === lastCheckedStepId ? false : true })));

	function onClickAccordion(id: number) {
		return () => {
			setAccordionHidden((prevState) =>
				prevState.map(
					(item) =>
						item.id === id
							? { ...item, isHidden: !item.isHidden } // Toggle the clicked item's visibility
							: { ...item, isHidden: true } // Close all other items
				)
			);
		};
	}

	function getAccordionHidden(id: number) {
		const accordion = hiddenAccordion.filter((value: AccordionProps) => value.id === id)[0];
		return accordion ? accordion.isHidden : false;
	}

	return { onClickAccordion, hiddenAccordion, getAccordionHidden };
};
