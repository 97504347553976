import React, { ReactNode, forwardRef } from "react";
import { ThemeProvider } from "styled-components";
import { Container } from "@material-ui/core";
import GlobalStyle from "../../styles/global";
import { AppThemes } from "../../roambee-client-styleguide";
import { Title } from "../Typography";
import { CloseButton, CloseIconWrapper, Content, ContentHeader, ContentWrapper, RightPanel } from "./styles";

type DrawerProps = {
	children: ReactNode;
	open: boolean;
	setOpen: (open: boolean) => void;
	title: string;
	setRowSelection?: (selection: any) => void; // Made setRowSelection optional
};

const DrawerPanel = forwardRef<HTMLDivElement, DrawerProps>(({ children, open, setOpen, title, setRowSelection }, ref) => {
	const toggleDrawer = () => {
		setOpen(!open);
		setRowSelection && setRowSelection({}); // Check if setRowSelection is defined
	};

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<RightPanel variant="persistent" anchor="right" open={open} ref={ref}>
					<ContentWrapper>
						<ContentHeader>
							<Title $bold>{title}</Title>
							<CloseIconWrapper>
								<CloseButton onClick={toggleDrawer} size={20} />
							</CloseIconWrapper>
						</ContentHeader>
						<Content>{children}</Content>
					</ContentWrapper>
				</RightPanel>
			</Container>
		</ThemeProvider>
	);
});

export default DrawerPanel;
