import InfoDetail from "../Infobox/InfoDetail";
import { ArrowUpRight, Checkmark, ChevronUp } from "@carbon/icons-react";

import { useLogic } from "./logic";
import { AccordionIconButton, ArrowIconButton, Circle, Container, Date, IconWrapper, InfoDetailWrapper, InfoHidden, Title, TitleWrapper, Step, StepContent, StepWrapper, TagWrapper, List, LinkButton } from "./styles";
import type { HistoryProps, StepProps } from "./types";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../styles/global";
import AppThemes from "../../styles/themes";
import { Overline } from "../Typography";

export default function History({ steps, iconAriaLabel }: HistoryProps): JSX.Element {
	// Determine the last checked step ID
	const lastCheckedStepId = steps ? [...steps].reverse().find((step) => step.checked)?.id : undefined;

	// Pass the last checked step ID to useLogic
	const { onClickAccordion, getAccordionHidden } = useLogic(steps ? [...steps].map((step) => step.id) : [], lastCheckedStepId);

	const stepItem = steps
		? [...steps].reverse().map(({ id, active, title, checked, statusColor, status, color, hasLink, infoDetails, hasExternalLink, missed }: StepProps) => (
				<StepWrapper key={`step-${id}`} $active={active}>
					<StepContent $checked={checked} $missed={missed}>
						<Circle $checked={checked} $missed={missed}>
							{missed ? id : checked ? <Checkmark /> : id}
						</Circle>
						<Step $active={!getAccordionHidden(id)}>
							<TagWrapper>
								<Overline>{status}</Overline>
								{hasExternalLink ? (
									<IconWrapper $hiddenAccordion={getAccordionHidden(id)}>
										<ArrowIconButton
											size="tiny"
											variant="no-bg"
											onClick={(e) => {
												e.preventDefault();
											}}
											ariaLabel="More information"
											icon={<ArrowUpRight size={20} />}
										/>
									</IconWrapper>
								) : null}
							</TagWrapper>
							<TitleWrapper $active={!getAccordionHidden(id)}>
								<Title $bold>{title}</Title>
								<AccordionIconButton ariaLabel={iconAriaLabel} icon={<ChevronUp />} onClick={onClickAccordion(id)} size="tiny" variant="no-bg" $hiddenAccordion={getAccordionHidden(id)} id={id.toString()} />
							</TitleWrapper>
							{active ? (
								<InfoHidden hidden={getAccordionHidden(id)}>
									{infoDetails &&
										infoDetails.map(({ title, detail, date, titleIcon, tooltipText }) => (
											<InfoDetailWrapper key={title}>
												<InfoDetail data={title} detail={detail} titleIcon={titleIcon} tooltipText={tooltipText} />
												<Date>{date}</Date>
											</InfoDetailWrapper>
										))}

									{hasLink && (
										<LinkButton
											size="small"
											variant="no-bg"
											href="/"
											label="Force Stop Shipment"
											onClick={(e) => {
												e.preventDefault();
											}}
										/>
									)}
								</InfoHidden>
							) : null}
						</Step>
					</StepContent>
				</StepWrapper>
		  ))
		: null;

	return (
		<ThemeProvider theme={AppThemes.light}>
			<GlobalStyle />
			<Container>
				<List reversed>{stepItem}</List>
			</Container>
		</ThemeProvider>
	);
}
